<script setup lang="ts">
import { useCurrentElement, useElementVisibility, watchDebounced } from '@vueuse/core';
import { onMounted, ref } from 'vue';

import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import PopperWrapper from '@/components/base/layout/PopperWrapper.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLocalStorage } from '@/composables/useLocalStorage';

const DELAY_TO_TAG_AS_SEEN_MS = 5000;

const props = defineProps<{ location: 'account' | 'order-details' | 'order-history' | 'pdp' }>();

const show = ref(false);

const target = useCurrentElement<HTMLElement>();
const elementVisibility = useElementVisibility(target);
const { flags } = useFeatureFlags();

const tagAsSeen = () => {
  useLocalStorage(`review-popover-${props.location}`).set('seen', true);
};

const onClose = () => {
  tagAsSeen();
  show.value = false;
};

onMounted(() => {
  show.value =
    flags.leaveAReview && !useLocalStorage(`review-popover-${props.location}`).get<boolean>('seen');

  if (show.value) {
    watchDebounced(
      () => elementVisibility.value && target.value.checkVisibility?.() && show.value,
      (onScreen) => {
        if (onScreen) tagAsSeen();
      },
      { debounce: DELAY_TO_TAG_AS_SEEN_MS, immediate: true },
    );
  }
});
</script>

<template>
  <PopperWrapper arrow :interactive="false" placement="auto" :show="show" theme="dark" zIndex="5">
    <template #content>
      <div class="max-w-xs p-4">
        <div class="flex items-center justify-between">
          <LargeBodyText class="font-normal text-white">Write a review</LargeBodyText>
          <UnstyledButton aria-label="Close popup" class="flex text-white" @click="onClose">
            <CloseIcon :size="20" />
          </UnstyledButton>
        </div>
        <BaseBodyText class="mt-2 font-normal text-white">
          Leave a review for your favorite products
          {{ ['order-history', 'account'].includes(location) ? 'in your order history!' : 'here!' }}
        </BaseBodyText>
      </div>
    </template>
    <slot :dismiss="onClose" />
  </PopperWrapper>
</template>
